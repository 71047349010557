import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "@nextui-org/react";

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isAction, setIsAction] = useState(false);
    useEffect(() => {
        axios.get("https://backend.sorpa.com/users/admin/notification/list/").then(
            res => {
                console.log(res.data);
                setIsAction(res.data.actions)
                setNotifications(res.data.notifications)
            }
        ).catch(
            err => {console.log(err)}
        )
    }, []);
    return(
        <div className={"text-white flex flex-col gap-[16px] p-[16px] max-h-screen overflow-auto"}>
            <h1 className={"text-[24px] font-bold"}>Уведомления</h1>
            {
                notifications.length === 0 &&
                <div>
                    <p className={"text-white"}>Уведомления не найдены</p>
                </div>
            }
            {
                notifications?.map((notification, index) => {
                    return(
                        <div key={index} className={"bg-[#121212FF] max-w-[500px] rounded-md p-[12px] flex flex-col gap-[16px] border-white border-[1px]"}>
                            <h2 className={"font-bold text-[14px]"}>{notification.title}</h2>
                            <p className={`text-[14px] font-light ${notification.not_status === "Ожидает" ? "text-white" : notification.not_status === "Изменено" ? "text-[green]": "text-[crimson]"}`}>{notification.not_status}</p>
                            <p className={"text-[14px] font-light whitespace-pre-wrap"}>{notification.text}</p>
                            {
                                isAction && notification.not_status === "Ожидает" &&
                                <div className={"flex gap-[12px]"}>
                                    <Button fullWidth={true} size={"sm"} color={"secondary"} className={"text-black"}>Отклонить</Button>
                                    <Button fullWidth={true} size={"sm"} color={"primary"} className={"text-black"}>Принять</Button>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Notifications;
