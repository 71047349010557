import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, ModalContent, ModalHeader, ModalBody, Textarea, Modal, Select, SelectItem, Spinner
} from "@nextui-org/react";
import React, {useContext, useEffect, useState} from "react";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {EditIcon} from "../../assets/icons/EditIcon";
import axios from "axios";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import AuthContext from "../../context/authContext";
import {useAsyncList} from "@react-stately/data";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {useNavigate} from "react-router";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import {PlayIcon} from "../../assets/icons/PlayIcon";
import {PauseIcon} from "../../assets/icons/PauseIcon";

const Deployers = () => {
    const history = useNavigate();
    const [count, setCount] = useState(0);
    const [userID, setUserID] = useState(null);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState({});
    const [hasMore, setHasMore] = React.useState(false);
    const [error, setError] = React.useState("");
    let url = "https://backend.sorpa.com/users/admin/deployers/list/";
    let list = useAsyncList({
        async load({signal, cursor}) {
            if (cursor) {
                setIsLoading(false);
            }
            const res = await axios.get(cursor || url);
            let json = await res.data
            setIsLoading(false);
            setUsers(json.results);
            setCount(json.count);
            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });
    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});
    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        url = `https://backend.sorpa.com/users/admin/deployers/list/?${searchParams}`
        list.reload();
    }, [search]);

    const [isLoading, setIsLoading] = useState(false);
    const [newDeployerModal, setNewDeployerModal] = useState(false);
    const [sendData, setSendData] = useState({
        "username": "",
        "password": "",
        "phone_number": "",
        "email": "",
        "name": "",
        "note": ""
    })
    const submitData = e => {
        e.preventDefault();
        // setIsLoading(true);
        let send_url = "https://backend.sorpa.com/users/admin/deployers/new/"
        if (userID){
            send_url = `https://backend.sorpa.com/users/admin/deployers/${userID}/edit/`
        }
        axios.post(send_url, {...sendData}).then(
            res => {
                setError("");
                setIsLoading(false);
                setNewDeployerModal(false);
                setSendData({
                    "username": "",
                    "password": "",
                    "phone_number": "",
                    "email": "",
                    "name": "",
                    "note": ""
                })
                list.reload();
            }
        ).catch(
            err => {
                setError(err.response.data.message);
            }
        )
        console.log(url);
    }
    return(
        <div style={{
            color: "white",
            width: "100%",
            height: "calc(100vh - 72px)",
            boxSizing: "border-box",
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                background: "black",
                maxWidth: 1300,
                height: "100%"
            }}>
                <h1 className={"pt-[12px] text-[24px] font-bold"}>Внедренцы</h1>
                <Card style={{width: "100%", background: "#121212", padding: 24}} className={"flex flex-col gap-[24px]"}>
                    <Button
                        onClick={() => {
                            setNewDeployerModal(true)
                        }}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "none",
                            color: "#BDFF00",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Добавить Внедренца</p>
                    </Button>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        gap: 4,
                        alignItems: "center"
                    }}>
                        <div style={{width: 240}}>
                            <Input
                                startContent={<SearchIcon/>}
                                type={"email"}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                placeholder={"Поиск по ФИО"}
                                onChange={(event) => {
                                    setSearch({...search, search: event.target.value});
                                    if (event.target.value === "") {
                                        const temp_search = {...search}
                                        delete temp_search.search
                                        setSearch(temp_search);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {
                        isLoading && count === 0 ? <>
                        <Spinner color={"primary"}/>
                        </> :
                            <>
                                {
                                    !isLoading && count === 0 &&
                                    <div>
                                        <p className={"font-bold text-white text-[16px]"}>По вашему запросу ничего не найдено</p>
                                    </div>
                                }
                            </>
                    }
                    {
                        !isLoading && count > 0 && <Table
                            isStriped isHeaderSticky
                            classNames={{
                                base: 'table-wrapper',
                                table: "table",
                                wrapper: "base-wrapper max-w-[1500px]"
                            }}
                            className={"h-full"}
                            aria-label="Example table with infinite pagination"
                            baseRef={scrollerRef}
                            bottomContent={
                                hasMore ? (
                                    <div ref={loaderRef} className="flex w-full justify-center">
                                        {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                                    </div>
                                ) : null
                            }
                        >
                            <TableHeader>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>ID</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>Логин</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>Пароль</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>Номер телефона</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>ФИО</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>Почта</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>Заметки</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold"
                                }}>Активный аккаунт?</TableColumn>
                                <TableColumn style={{
                                    background: "#525255",
                                    color: "white",
                                    fontWeight: "bold",
                                    textAlign: "center"
                                }}>Действия</TableColumn>
                            </TableHeader>
                            <TableBody
                                isLoading={isLoading}
                                items={list.items}
                                // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                            >
                                {(user) => (
                                    <TableRow>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.id}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.username}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.staff_password}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.phone_number}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.name}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.email}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.note}</p></TableCell>
                                        <TableCell><p style={{
                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                            margin: 0
                                        }}>{user.is_active ? "Да" : "Нет"}</p></TableCell>
                                        <TableCell style={{width: 120}}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                <Tooltip content="Редактировать">
                                                    <Button isIconOnly={true}
                                                            onClick={() => {
                                                                setUserID(user.id);
                                                                axios.get(`https://backend.sorpa.com/users/admin/deployers/${user.id}/details/`).then(
                                                                    res => {
                                                                        console.log(res.data);
                                                                        setSendData({
                                                                            "username": res.data.username,
                                                                            "password": res.data.staff_password,
                                                                            "phone_number": res.data.phone_number,
                                                                            "email": res.data.email,
                                                                            "name": res.data.name,
                                                                            "note": res.data.note
                                                                        })
                                                                        setNewDeployerModal(true);
                                                                    }
                                                                ).catch(
                                                                    err => {}
                                                                )
                                                            }}
                                                            style={{background: "none"}}>
                                                        <EditIcon/>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip content="Остановить">
                                                    <Button onClick={() => {
                                                        axios.get(`https://backend.sorpa.com/users/admin/deployers/${user.id}/stop/`).then(
                                                            res => {
                                                                console.log(res);
                                                                list.reload();
                                                            }
                                                        ).catch(
                                                            err => {
                                                                console.log(err)
                                                            }
                                                        )
                                                    }} isIconOnly={true}
                                                            style={{background: "none"}}>
                                                        {user.is_active ? <PauseIcon/> : <PlayIcon/>}
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    }
                </Card>
            </div>
            <Modal isOpen={newDeployerModal} style={{background: "#121212"}} hideCloseButton={true} isDismissable={false}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Добавить</h2>
                        <Button
                            disabled={isLoading}
                            onClick={() => {
                            setNewDeployerModal(false);
                            setError("");
                            setSendData({
                                "username": "",
                                "password": "",
                                "phone_number": "",
                                "email": "",
                                "name": "",
                                "note": ""
                            })
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        <form
                            className={"flex flex-col gap-[12px]"}
                            onSubmit={submitData}
                        >
                            <p className={"text-[crimson]"}>{error}</p>
                            <Input
                                disabled={isLoading}
                                isRequired={true}
                                type={"text"}
                                label="Логин"
                                value={sendData.username}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setError("");
                                    setSendData({...sendData, username: event.target.value})
                                }}
                            />
                            <Input
                                disabled={isLoading}
                                isRequired={true}
                                type={"text"}
                                label="Пароль"
                                value={sendData.password}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setError("");
                                    setSendData({...sendData, password: event.target.value})
                                }}
                            />
                            <Input
                                disabled={isLoading}
                                isRequired={true}
                                type={"email"}
                                label="Почта"
                                value={sendData.email}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setError("");
                                    setSendData({...sendData, email: event.target.value})
                                }}
                            />
                            <Input
                                disabled={isLoading}
                                isRequired={true}
                                type={"text"}
                                label="Номер телефона"
                                value={sendData.phone_number}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setError("");
                                    setSendData({...sendData, phone_number: event.target.value})
                                }}
                            />
                            <Input
                                disabled={isLoading}
                                isRequired={true}
                                type={"text"}
                                label="ФИО"
                                value={sendData.name}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setError("");
                                    setSendData({...sendData, name: event.target.value})
                                }}
                            />
                            <Textarea
                                disabled={isLoading}
                                isRequired={true}
                                type={"text"}
                                label="Заметка"
                                value={sendData.note}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setError("");
                                    setSendData({...sendData, note: event.target.value})
                                }}
                            />
                            <Button
                                disabled={isLoading}
                                fullWidth={true}
                                style={{
                                background: "#BF0",
                                color: "black",
                                fontSize: 16,
                                fontWeight: 600,
                                marginBottom:20
                            }}
                                    type={"submit"}
                            >
                                <p>Добавить</p>
                            </Button>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default Deployers;
