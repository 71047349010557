import {EmployersTopNav} from "./EmployersTopNav";
import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, Spinner
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";
import {useAsyncList} from "@react-stately/data";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
const Employers = () => {
    const [isLoading, setIsLoading] = useState(true);
    const history = useNavigate();
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState({});
    const [places, setPlaces] = useState([]);
    const {filterCities, filterCategories} = useContext(AuthContext);
    const [existSearch, setExistSearch] = useState("");
    const [hasMore, setHasMore] = React.useState(false);
    let url = "https://backend.sorpa.com/places/admin/list/";
    let list = useAsyncList({
        async load({signal, cursor}) {
            if (cursor) {
                setIsLoading(false);
            }
            const res = await axios.get(cursor || url);
            let json = await res.data
            setIsLoading(false);
            setPlaces(json.results);
            setCount(json.count);
            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });
    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});
    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        url = `https://backend.sorpa.com/places/admin/list/?${searchParams}`
        list.reload();
    }, [search]);
    return (
        <>
            <EmployersTopNav/>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16
                        }}>
                            <p style={{color: "white", fontWeight: "bold"}}>{count} предприятий</p>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 24,
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <div style={{width: 240}}>
                                        <Input
                                            startContent={<SearchIcon/>}
                                            type={"email"}
                                            size={"sm"}
                                            radius="lg"
                                            style={{color: "white"}}
                                            className={"input-text-color"}
                                            classNames={{
                                                label: "input-text-color",
                                                input: "text-white/90",
                                                innerWrapper: "input-text-color",
                                                inputWrapper: "input-main",
                                            }}
                                            placeholder={"Поиск"}
                                            onChange={(event) => {
                                                setSearch({...search, search: event.target.value});
                                                if (event.target.value === "") {
                                                    const temp_search = {...search}
                                                    delete temp_search.search
                                                    setSearch(temp_search);
                                                }
                                            }}
                                        />
                                    </div>
                                    {/*<Button style={{*/}
                                    {/*    height: "44px",*/}
                                    {/*    background: "#BF0",*/}
                                    {/*    color: "black",*/}
                                    {/*    fontWeight: 600*/}
                                    {/*}}*/}
                                    {/*        onClick={submitSearch}*/}
                                    {/*>*/}
                                    {/*    <p>Найти</p>*/}
                                    {/*</Button>*/}
                                </div>
                                <div style={{width: 240}}>
                                    <Select
                                        placeholder={"Все города"}
                                        value={search.city_id}
                                        onChange={(items) => {
                                            setSearch({...search, city_id: items.target.value});
                                            if (!items.target.value || items.target.value === "0") {
                                                const temp_search = {...search}
                                                delete temp_search.city_id
                                                setSearch(temp_search);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {filterCities.map((city) => (
                                            <SelectItem key={city.id} value={city.name}>
                                                {city.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: 240}}>
                                    <Select
                                        placeholder={"Все категории"}
                                        value={search.category_id}
                                        onChange={(items) => {
                                            setSearch({...search, category_id: items.target.value});
                                            if (!items.target.value || items.target.value === "0") {
                                                const temp_search = {...search}
                                                delete temp_search.category_id
                                                setSearch(temp_search);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {filterCategories.map((category) => (
                                            <SelectItem key={category.id} value={category.name}>
                                                {category.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: 240}}>
                                    <a href={"/employers/change_positions"} style={{color:"white", fontWeight:900}}>
                                        Изменить порядок
                                    </a>
                                </div>
                            </div>
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            <div>
                                {
                                    count === 0 ?
                                        <div style={{
                                            width: "100%",
                                            height: "calc(100vh - 250px)",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <>
                                            {
                                                isLoading ? <Spinner color={"primary"}/>
                                                    :
                                                <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                    вашему запросу ничего не найдено</p>
                                            }
                                            </>
                                        </div> :
                                        <Table
                                            isStriped isHeaderSticky
                                            classNames={{
                                                base: 'table-wrapper',
                                                table: "table",
                                                wrapper: "base-wrapper max-w-[1500px]"
                                            }}
                                            className={"h-full"}
                                            aria-label="Example table with infinite pagination"
                                            baseRef={scrollerRef}
                                            bottomContent={
                                                hasMore ? (
                                                    <div ref={loaderRef} className="flex w-full justify-center">
                                                        {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                                                    </div>
                                                ) : null
                                            }
                                        >
                                            <TableHeader>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Наименование</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Показан в <br/> приложении?</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Город</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Филиалы</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Категория</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Скидка</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textAlign: "center"
                                                }}>Действия</TableColumn>
                                            </TableHeader>
                                            <TableBody
                                                isLoading={isLoading}
                                                items={list.items}
                                                // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                                            >
                                                {(place) => (
                                                    <TableRow>
                                                        <TableCell><p style={{
                                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                            margin: 0
                                                        }}>{place.name}</p></TableCell>
                                                        <TableCell><p style={{
                                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                            margin: 0
                                                        }}>{place.is_shown ? "Да" : "Нет"}</p></TableCell>
                                                        <TableCell><p style={{
                                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                            margin: 0
                                                        }}>{place.city}</p></TableCell>
                                                        <TableCell><p style={{
                                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                            margin: 0
                                                        }}>{place.branch_count}</p></TableCell>
                                                        <TableCell><p style={{
                                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                            margin: 0
                                                        }}>{place.category}</p></TableCell>
                                                        <TableCell><p style={{
                                                            borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                            margin: 0
                                                        }}>{place.off_percent}</p></TableCell>
                                                        <TableCell style={{width: 120}}>
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}>
                                                                <Tooltip content="Редактировать">
                                                                    <Button isIconOnly={true}
                                                                            onClick={() => {history(`/employers/${place.id}`)}}
                                                                            style={{background: "none"}}>
                                                                        <EditIcon/>
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip content="Удалить">
                                                                    <Button onClick={() => {
                                                                        axios.get(`https://backend.sorpa.com/places/admin/${place.id}/delete/`).then(
                                                                            res => {
                                                                                console.log(res);
                                                                                const searchParams = new URLSearchParams(search);
                                                                                axios.get(`https://backend.sorpa.com/places/admin/list/${searchParams}`).then(
                                                                                    res => {
                                                                                        setPlaces(res.data);
                                                                                    }
                                                                                ).catch(
                                                                                    err => {
                                                                                        console.log(err);
                                                                                    }
                                                                                )
                                                                            }
                                                                        ).catch(
                                                                            err => {
                                                                                console.log(err)
                                                                            }
                                                                        )
                                                                    }} isIconOnly={true}
                                                                            style={{background: "none"}}>
                                                                        <DeleteIcon/>
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                }
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Employers;
