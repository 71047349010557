import {useContext, useEffect, useState} from "react";
import AuthContext from "./context/authContext";
import {BrowserRouter as Router} from 'react-router-dom';
import useRoutes from "./routes";
import SideNav from "./components/SideNav";
import axios from "axios";

function App() {
    const {auth} = useContext(AuthContext);
    const routes = useRoutes(auth);
  return (
      <div style={{display:"flex"}}>
          <Router>
              {auth && <SideNav/>}
              <div style={{width:"100%", height:"100vh"}}>
                  {routes}
              </div>
          </Router>
      </div>
  );
}

export default App;
