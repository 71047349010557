import {
    Button,
    Card,
    Checkbox,
    Input, Modal, ModalBody, ModalContent, ModalHeader,
    Select,
    SelectItem,
    Table, TableBody, TableCell,
    TableColumn,
    TableHeader, TableRow, Textarea,
    Tooltip
} from "@nextui-org/react";
import {useNavigate, useParams} from "react-router";
import {BackIcon} from "../../assets/icons/BackIcon";
import {AddIcon} from "../../assets/icons/AddIcon";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import axios from "axios";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import {EditIcon} from "../../assets/icons/EditIcon";

const BranchDetails = () => {
    const headers = {'Content-Type': 'multipart/form-data'};
    const history = useNavigate();
    const params = useParams();
    const [personModal, setPersonModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [sendData, setSendData] = useState({
        name: "",
        admin_name: "",
        phone_number: "",
        login: "",
        password: "",
        bin_number: "",
        account_number: "",
        off_percent: "",
        city: "",
        category: "",
        lng: "",
        lat: "",
        is_shown: true,
        description: "",
        monday_start: "",
        monday_end: "",
        tuesday_start: "",
        tuesday_end: "",
        wednesday_start: "",
        wednesday_end: "",
        thursday_start: "",
        thursday_end: "",
        friday_start: "",
        friday_end: "",
        saturday_start: "",
        saturday_end: "",
        sunday_start: "",
        sunday_end: "",
        personal: []
    });
    const [personal, setPersonal] = useState({
        id: null,
        name: "",
        phone_number: "",
        position: "",
        password: "",
        login: "",
    });
    const [city, setCity] = useState(null);
    const [category, setCategory] = useState(null);
    const [gallery, setGallery] = useState([]);
    const [previews, setPreview] = useState([]);
    const [positionExists, setPositionExists] = useState(true);
    const [positions, setPositions] = useState([]);
    const [personalError, setPersonalError] = useState('');
    const [existingGallery, setExistingGallery] = useState([]);
    const {filterCities, filterCategories, setFilterCategories, setFilterCities} = useContext(AuthContext);

    useEffect(() => {
        axios.get("https://backend.sorpa.com/properties/admin/list/").then(
            res => {
                let categories = []
                let cities = []
                res.data.sub_categories.map((item) => {
                    if (item.id !== 0) {
                        categories.push(item)
                    }
                })
                res.data.cities.map((item) => {
                    if (item.id !== 0) {
                        cities.push(item)
                    }
                })
                setFilterCategories(categories);
                setFilterCities(cities);
                setPositions(res.data.positions);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);

    const addPhoto = (event) => {
        const file = event.target.files[0];
        const gallery_temp = [...gallery]
        const previews_temp = [...previews]
        if (file) {
            gallery_temp.push(file);
            previews_temp.push(URL.createObjectURL(file));
            setGallery(gallery_temp);
            setPreview(previews_temp);
        }
    }

    const deletePhoto = (index) => {
        const gallery_temp = [...gallery]
        const previews_temp = [...previews]
        gallery_temp.splice(index, 1);
        previews_temp.splice(index, 1);
        setGallery(gallery_temp);
        setPreview(previews_temp);
    }

    const addPersonal = ({type}) => {
        console.log(personal);
        if (personal.name === '') {
            return setPersonalError("Укажите имя персонала")
        } else if (personal.position === '') {
            return setPersonalError("Укажите должность персонала")
        } else if (personal.phone_number === '') {
            return setPersonalError("Укажите номер телефона персонала")
        } else if (personal.password === '') {
            return setPersonalError("Укажите пароль персонала")
        }
        else if (personal.login === '') {
            return setPersonalError("Укажите login персонала")
        }
        if (personal.id === null){
            let temp_personal = [...sendData.personal]

            let is_exist = false
            temp_personal.map((item, index) => {
                    if (item.phone_number === personal.phone_number) {
                        return is_exist = true
                    }
                    return is_exist = false
                }
            )

            if (is_exist) {
                return setPersonalError("Персонал с таким номером уже существует")
            }
            temp_personal.push(personal)
            setSendData({...sendData, personal: temp_personal});
        }else{
            console.log("123");
            axios.post(`https://backend.sorpa.com/places/admin/personal/${personal.id}/edit/`, {...personal}).then(
                res => {
                    let temp_branch = {
                        name: res.data.name,
                        admin_name: res.data.admin_name,
                        phone_number: res.data.phone_number,
                        login: res.data.login,
                        password: res.data.password,
                        bin_number: res.data.bin_number,
                        account_number: res.data.account_number,
                        off_percent: res.data.off_percent,
                        city: res.data.city,
                        category: res.data.category,
                        lng: res.data.lng,
                        lat: res.data.lat,
                        description: res.data.description,
                        address: res.data.address,
                        personal: res.data.personal_set,
                        monday_start: res.data.monday_start,
                        monday_end: res.data.monday_end,
                        tuesday_start: res.data.tuesday_start,
                        tuesday_end: res.data.tuesday_end,
                        wednesday_start: res.data.wednesday_start,
                        wednesday_end: res.data.wednesday_end,
                        thursday_start: res.data.thursday_start,
                        thursday_end: res.data.thursday_end,
                        friday_start: res.data.friday_start,
                        friday_end: res.data.friday_end,
                        saturday_start: res.data.saturday_start,
                        saturday_end: res.data.saturday_end,
                        sunday_start: res.data.sunday_start,
                        sunday_end: res.data.sunday_end,
                    }
                    setExistingGallery(res.data.photo_gallery_set);
                    setSendData(temp_branch);
                }
            ).catch(
                err => {console.log(err)}
            )
        }
        setPersonal({
            id: null,
            name: "",
            phone_number: "",
            position: "",
            password: "",
            login:""
        })
        setPersonModal(false);
    }

    const deletePersonal = (index) => {
        if (sendData.personal[index].id !== null){
            axios.get(`https://backend.sorpa.com/places/personal/${sendData.personal[index].id}/${params.branch_id}/delete/`).then(
                res => {console.log(res.data)}
            ).catch(
                err => {console.log(err)}
            )
        }
        const personal_temp = [...sendData.personal]
        personal_temp.splice(index, 1);
        setSendData({...sendData, personal: personal_temp});
    }

    const openPersonal = (index) => {
        setPersonModal(true);
        const personal = sendData.personal[index]
        setPersonal({
            id: personal.id,
            name: personal.name,
            phone_number: personal.phone_number,
            position: personal.position,
            password: personal.password,
            login:personal.login
        })
    }

    const submitData = () => {
        axios.post(`https://backend.sorpa.com/places/admin/branch/${params.branch_id}/edit/`, {...sendData}).then(
            res => {
                if (gallery.length > 0) {
                    gallery.map((photo, index) => {
                            let formData = new FormData();
                            formData.append("photo", photo);
                            return (
                                axios.post(`https://backend.sorpa.com/places/admin/branch/${res.data.id}/upload/photo/`, formData, {headers: headers}).then(
                                    res => {
                                        if (index === gallery.length - 1 && res) {
                                            setLoading(false);
                                            history(params.employer_id !== "0" ? `/employers/${params.employer_id}` : `/notifications`);
                                        }
                                    }
                                ).catch(
                                    err => {
                                        console.log(err)
                                    }
                                )
                            )
                        }
                    )
                } else {
                    setLoading(false);
                    history(params.employer_id !== "0" ? `/employers/${params.employer_id}` : `/notifications`);
                }
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        axios.get(`https://backend.sorpa.com/places/admin/branch/${params.branch_id}/details/`).then(
            res => {
                let temp_branch = {
                    name: res.data.name,
                    admin_name: res.data.admin_name,
                    phone_number: res.data.phone_number,
                    login: res.data.login,
                    password: res.data.password,
                    bin_number: res.data.bin_number,
                    account_number: res.data.account_number,
                    off_percent: res.data.off_percent,
                    city: res.data.city,
                    category: res.data.category,
                    lng: res.data.lng,
                    lat: res.data.lat,
                    is_shown: res.data.is_shown,
                    description: res.data.description,
                    address: res.data.address,
                    personal: res.data.personal_set,
                    monday_start: res.data.monday_start,
                    monday_end: res.data.monday_end,
                    tuesday_start: res.data.tuesday_start,
                    tuesday_end: res.data.tuesday_end,
                    wednesday_start: res.data.wednesday_start,
                    wednesday_end: res.data.wednesday_end,
                    thursday_start: res.data.thursday_start,
                    thursday_end: res.data.thursday_end,
                    friday_start: res.data.friday_start,
                    friday_end: res.data.friday_end,
                    saturday_start: res.data.saturday_start,
                    saturday_end: res.data.saturday_end,
                    sunday_start: res.data.sunday_start,
                    sunday_end: res.data.sunday_end,
                }
                setExistingGallery(res.data.photo_gallery_set);
                setSendData(temp_branch);
                setLoading(false);
            }
        ).catch(
            err => {console.log(err)}
        )
    }, []);

    if(loading){
        return null
    }

    return (
        <div style={{
            color: "white",
            width: "100%",
            height: "100vh",
            boxSizing: "border-box",
            overflow: "auto",
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16,
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                background: "black",
                maxWidth: 800,
                height: "100%",
                marginTop: 32,
                marginBottom: 20
            }}>
                <div onClick={() => {
                    history(params.employer_id !== "0" ? `/employers/${params.employer_id}` : `/employers`)
                }} style={{
                    color: "white",
                    width: 240,
                    padding: 0,
                    fontSize: 18,
                    background: "none",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    cursor: "pointer"
                }}><BackIcon/>Предприятие
                </div>
                {/*кнопки*/}
                <div style={{marginTop: 16, display: "flex", gap: 16}}>
                    <Button
                        onClick={submitData}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "#BDFF00",
                            color: "black",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Сохранить</p>
                    </Button>
                    <Button
                        onClick={() => {
                            history(params.employer_id === "0" ? "/employers" : `/employers/${params.employer_id}`)
                        }}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "none",
                            color: "#BDFF00",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Отмена</p>
                    </Button>
                </div>

                <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start", gap: 24}}>
                    <div>
                        <Card style={{
                            minWidth:800,
                            width: "100%",
                            background: "#121212",
                            color: "white",
                            padding: 24,
                            height: "100%",
                            marginTop: 16,
                            display: "flex",
                            flexDirection: "column",
                            gap: 20
                        }}>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display: "flex", flexDirection: "row", gap: 20, alignItems: "center", height: "100%"}}>
                                <div>
                                    <Tooltip content="Нажмите чтобы добавить фото в фотогалерею">
                                        <label htmlFor={"logo-input"}>
                                            <div style={{
                                                width: 78,
                                                height: 78,
                                                background: "#2A2A2C",
                                                borderRadius: 12,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <AddIcon/>
                                            </div>
                                        </label>
                                    </Tooltip>
                                    <input accept={"image"} id={"logo-input"} type={"file"}
                                           style={{display: "none"}}
                                           onChange={(event) => {
                                               addPhoto(event)
                                           }}
                                    />
                                </div>
                                <div style={{
                                    width: "100%",
                                    minWidth: 78,
                                    overflowX: "auto",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 10
                                }}>
                                    {
                                        previews.map((preview, index) => (
                                            <div style={{position: "relative", width:78, height:78}}>
                                                <Button style={{
                                                    position: "absolute",
                                                    right: -10,
                                                    top: -10,
                                                    background: "none",
                                                    zIndex: 10
                                                }} isIconOnly={true} onClick={() => {
                                                    deletePhoto(index)
                                                }}><CloseIcon/></Button>
                                                <img src={preview} key={index} style={{
                                                    width: 78,
                                                    height: 78,
                                                    borderRadius: 12,
                                                    objectFit: "cover",
                                                    background: "black"
                                                }}/>
                                            </div>
                                        ))
                                    }
                                    {
                                        existingGallery.map((preview, index) => (
                                            <div key={index} style={{position: "relative", width:78, height:78}}>
                                                <Button style={{
                                                    position: "absolute",
                                                    right: -10,
                                                    top: -10,
                                                    background: "none",
                                                    zIndex: 10
                                                }} isIconOnly={true} onClick={() => {
                                                    axios.get(`https://backend.sorpa.com/places/admin/branch/${params.branch_id}/photo/${preview.id}/delete/`).then(
                                                        res => {
                                                            setExistingGallery(res.data);
                                                        }
                                                    ).catch(
                                                        err => {console.log(err)}
                                                    )
                                                }}><CloseIcon/></Button>
                                                <img src={`https://backend.sorpa.com/media/${preview.photo}`} key={index} style={{
                                                    width: 78,
                                                    height: 78,
                                                    borderRadius: 12,
                                                    objectFit: "cover",
                                                    background: "black"
                                                }}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                <Input
                                    type={"text"}
                                    label="Доп название"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.name}
                                    onChange={(event) => {
                                        setSendData({...sendData, name: event.target.value});
                                        setError("");
                                    }}
                                />
                                <Input
                                    type={"text"}
                                    label="Имя Админа"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.admin_name}
                                    onChange={(event) => {
                                        setSendData({...sendData, admin_name: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{width: "50%", paddingRight: 5}}>
                                <Input
                                    type={"tel"}
                                    label="Контактный номер админа"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.phone_number}
                                    onChange={(event) => {
                                        setSendData({...sendData, phone_number: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                <Input
                                    type={"text"}
                                    label="Логин"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.login}
                                    onChange={(event) => {
                                        setSendData({...sendData, login: event.target.value});
                                        setError("");
                                    }}
                                />
                                <Input
                                    type={"text"}
                                    label="Пароль"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.password}
                                    onChange={(event) => {
                                        setSendData({...sendData, password: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                <Input
                                    type={"text"}
                                    label="БИН"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.bin_number}
                                    onChange={(event) => {
                                        setSendData({...sendData, bin_number: event.target.value});
                                        setError("");
                                    }}
                                />
                                <Input
                                    type={"text"}
                                    label="Номер счета"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.account_number}
                                    onChange={(event) => {
                                        setSendData({...sendData, account_number: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{width: "50%", paddingRight: 5}}>
                                <Input
                                    type={"text"}
                                    label="Процент скидки"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.off_percent}
                                    onChange={(event) => {
                                        setSendData({...sendData, off_percent: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                <div style={{width: "100%"}}>
                                    <Select
                                        placeholder={"Выбрать город"}
                                        value={city}
                                        defaultSelectedKeys={[sendData.city]}
                                        onChange={(items) => {
                                            setCity(items.target.value);
                                            setSendData({...sendData, city: items.target.value});
                                            if (!items.target.value || items.target.value === "0") {
                                                setCity(null);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {filterCities.map((city) => (
                                            <SelectItem key={city.id} value={city.name}>
                                                {city.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: "100%"}}>
                                    <Select
                                        placeholder={"Выбрать категорию"}
                                        value={category}
                                        defaultSelectedKeys={[sendData.category]}
                                        onChange={(items) => {
                                            setCategory(items.target.value);
                                            setSendData({...sendData, category: items.target.value});
                                            if (!items.target.value || items.target.value === "0") {
                                                setCategory(null);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {filterCategories.map((category) => (
                                            <SelectItem key={category.id} value={category.name}>
                                                {category.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                                <Input
                                    type={"number"}
                                    label="Долгота"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.lng}
                                    onChange={(event) => {
                                        setSendData({...sendData, lng: event.target.value});
                                        setError("");
                                    }}
                                />
                                <Input
                                    type={"number"}
                                    label="Широта"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.lat}
                                    onChange={(event) => {
                                        setSendData({...sendData, lat: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{width: "50%", paddingRight: 5}}>
                                <Input
                                    type={"text"}
                                    label="Текстовый адрес"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.address}
                                    onChange={(event) => {
                                        setSendData({...sendData, address: event.target.value});
                                        setError("");
                                    }}
                                />
                            </div>
                            <div style={{width: "100%",  height:"100%"}}>
                                <Textarea
                                    type={"text"}
                                    label="Краткое описание"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    value={sendData.description}
                                    onChange={(event) => {
                                        setSendData({...sendData, description: event.target.value});
                                        setError("");
                                    }}
                                />
                                {
                                    localStorage.getItem("role") !== "Владелец" &&
                                    <Checkbox
                                        className={"mt-[10px]"}
                                        defaultSelected={sendData.is_shown}
                                        onChange={(value) => {
                                            if (value.target.checked){
                                                setSendData({...sendData, is_shown: true});
                                            }else{
                                                setSendData({...sendData, is_shown: false});
                                            }
                                        }}><p style={{color: "white"}}>Показать в приложении</p></Checkbox>
                                }
                            </div>
                        </Card>
                        <Card style={{
                            width: "100%",
                            background: "#121212",
                            color: "white",
                            height: "100%",
                            minHeight: 400,
                            padding: 24,
                            marginTop: 16,
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                        }}>
                            <div>
                                <Button
                                    startContent={<AddIcon/>}
                                    onClick={() => {
                                        setPersonModal(true)
                                    }}
                                    style={{
                                        height: "44px",
                                        background: "none",
                                        color: "white",
                                        fontWeight: 600
                                    }}
                                >
                                    <p>Добавить сотрудника</p>
                                </Button>
                                <Modal isOpen={personModal} style={{background: "#121212"}} hideCloseButton={true}>
                                    <ModalContent>
                                        <ModalHeader style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            paddingBottom: 0,
                                            paddingRight: 16
                                        }}>
                                            <h2 style={{color: "white"}}>Добавление сотрудника</h2>
                                            <Button onClick={() => {
                                                setPersonModal(false)
                                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                                        </ModalHeader>
                                        <ModalBody>
                                            {
                                                personalError && <p style={{color: "red"}}>{personalError}</p>
                                            }
                                            <Input
                                                type={"text"}
                                                label="ФИО"
                                                size={"sm"}
                                                radius="lg"
                                                style={{color: "white"}}
                                                className={"input-text-color"}
                                                classNames={{
                                                    label: "input-text-color",
                                                    input: "text-white/90",
                                                    innerWrapper: "input-text-color",
                                                    inputWrapper: "input-main",
                                                }}
                                                value={personal.name}
                                                onChange={(event) => {
                                                    setPersonal({...personal, name: event.target.value});
                                                    setPersonalError("");
                                                }}
                                            />
                                            <Input
                                                type={"text"}
                                                label="Номер телефона"
                                                size={"sm"}
                                                radius="lg"
                                                style={{color: "white"}}
                                                className={"input-text-color"}
                                                classNames={{
                                                    label: "input-text-color",
                                                    input: "text-white/90",
                                                    innerWrapper: "input-text-color",
                                                    inputWrapper: "input-main",
                                                }}
                                                value={personal.phone_number}
                                                onChange={(event) => {
                                                    setPersonal({...personal, phone_number: event.target.value});
                                                    setPersonalError("");
                                                }}
                                            />
                                            {
                                                positionExists ?
                                                    <Select
                                                        placeholder={"Выбрать должность"}
                                                        value={personal.position}
                                                        onChange={(items) => {
                                                            setPersonal({...personal, position: items.target.value});
                                                            setPersonalError("");
                                                        }}
                                                        listboxProps={{
                                                            itemClasses: {
                                                                base: "wrapper-content"
                                                            },
                                                        }}
                                                        popoverProps={{
                                                            classNames: {
                                                                content: "wrapper",
                                                            },
                                                        }}
                                                        disallowEmptySelection={false}
                                                        color={"secondary"}
                                                        size={"sm"}
                                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                                        classNames={{
                                                            label: "text-white",
                                                        }}
                                                    >
                                                        {positions.map((position) => (
                                                            <SelectItem key={position.id} value={position.name}>
                                                                {position.name}
                                                            </SelectItem>
                                                        ))}
                                                    </Select>
                                                    :
                                                    <Input
                                                        type={"text"}
                                                        label="Должность"
                                                        size={"sm"}
                                                        radius="lg"
                                                        style={{color: "white"}}
                                                        className={"input-text-color"}
                                                        classNames={{
                                                            label: "input-text-color",
                                                            input: "text-white/90",
                                                            innerWrapper: "input-text-color",
                                                            inputWrapper: "input-main",
                                                        }}
                                                        value={personal.position}
                                                        onChange={(event) => {
                                                            setPersonal({...personal, position: event.target.value});
                                                            setPersonalError("");
                                                        }}
                                                    />
                                            }
                                            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                                <Checkbox
                                                    onChange={(value) => {
                                                        setPositionExists(!value.target.checked)
                                                    }}
                                                ><p style={{color: "white"}}>Должность отсутствует в списке</p></Checkbox>
                                                <Tooltip
                                                    content={"Если должности нет в списке впишите ее вручную\n нажмите чтобы вписать"}>
                                                    <p style={{color: "white", padding: 20}}>?</p></Tooltip>
                                            </div>
                                            <Input
                                                type={"text"}
                                                label="Логин"
                                                size={"sm"}
                                                radius="lg"
                                                style={{color: "white"}}
                                                className={"input-text-color"}
                                                classNames={{
                                                    label: "input-text-color",
                                                    input: "text-white/90",
                                                    innerWrapper: "input-text-color",
                                                    inputWrapper: "input-main",
                                                }}
                                                value={personal.login}
                                                onChange={(event) => {
                                                    setPersonal({...personal, login: event.target.value});
                                                    setPersonalError("");
                                                }}
                                            />
                                            <Input
                                                type={"text"}
                                                label="Пароль"
                                                size={"sm"}
                                                radius="lg"
                                                style={{color: "white"}}
                                                className={"input-text-color"}
                                                classNames={{
                                                    label: "input-text-color",
                                                    input: "text-white/90",
                                                    innerWrapper: "input-text-color",
                                                    inputWrapper: "input-main",
                                                }}
                                                value={personal.password}
                                                onChange={(event) => {
                                                    setPersonal({...personal, password: event.target.value});
                                                    setPersonalError("");
                                                }}
                                            />
                                            <Button style={{
                                                marginBottom: 20,
                                                background: "#BF0",
                                                color: "black",
                                                fontSize: 16,
                                                fontWeight: 600
                                            }}
                                                    onClick={() => {addPersonal("new")}}
                                            >
                                                <p>Добавить</p>
                                            </Button>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                            </div>
                            <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                   classNames={{
                                       base: 'table-wrapper',
                                       table: "table",
                                       wrapper: "base-wrapper"
                                   }}
                            >
                                <TableHeader>
                                    <TableColumn style={{
                                        background: "#525255",
                                        color: "white",
                                        fontWeight: "bold"
                                    }}>ФИО</TableColumn>
                                    <TableColumn style={{
                                        background: "#525255",
                                        color: "white",
                                        fontWeight: "bold"
                                    }}>Номер</TableColumn>
                                    <TableColumn style={{
                                        background: "#525255",
                                        color: "white",
                                        fontWeight: "bold"
                                    }}>Должность</TableColumn>
                                    <TableColumn style={{
                                        background: "#525255",
                                        color: "white",
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}>Действия</TableColumn>
                                </TableHeader>
                                <TableBody>
                                    {
                                        sendData.personal.map((item, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.name}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.phone_number}</p></TableCell>
                                                    <TableCell><p style={{
                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                        margin: 0
                                                    }}>{item.position}</p></TableCell>
                                                    <TableCell style={{width: 120}}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}>
                                                            <Tooltip content="Редактировать">
                                                                <Button
                                                                    onClick={() => {
                                                                        openPersonal(index)
                                                                    }}
                                                                    isIconOnly={true}
                                                                    style={{background: "none"}}>
                                                                    <EditIcon/>
                                                                </Button>
                                                            </Tooltip>
                                                            <Tooltip content="Удалить">
                                                                <Button
                                                                    onClick={() => {
                                                                        deletePersonal(index)
                                                                    }}
                                                                    isIconOnly={true}
                                                                    style={{background: "none"}}>
                                                                    <DeleteIcon/>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Card>
                    </div>
                    <Card style={{
                        minWidth:400,
                        width: "100%",
                        background: "#121212",
                        color: "white",
                        padding: 24,
                        height: "100%",
                        maxHeight: 550,
                        marginTop: 16,
                        display: "flex",
                        flexDirection: "column",
                        gap: 20
                    }}>
                        <p style={{color:"white"}}>Часы работы</p>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>ПН</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.monday_start}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, monday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.monday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, monday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>ВТ</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                value={sendData.tuesday_start}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, tuesday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.tuesday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, tuesday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>СР</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.wednesday_start}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, wednesday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.wednesday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, wednesday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>ЧТ</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.thursday_start}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, thursday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.thursday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, thursday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>ПТ</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.friday_start}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, friday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.friday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, friday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>СБ</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.saturday_start}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, saturday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.saturday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, saturday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:16}}>
                            <p>ВС</p>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.sunday_start}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, sunday_start: event.target.value});
                                    setError("");
                                }}
                            />
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                placeholder={"--:--"}
                                style={{color: "white", textAlign:"center"}}
                                className={"input-text-color"}
                                maxLength={5}
                                value={sendData.sunday_end}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                onChange={(event) => {
                                    setSendData({...sendData, sunday_end: event.target.value});
                                    setError("");
                                }}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </div>

    )
}

export default BranchDetails;
