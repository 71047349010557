import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {Button, Spinner} from "@nextui-org/react";
import axios from "axios";

const ExcelSave = ({url, name}) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleExport = () => {
        setIsLoading(true);
        axios.get(url).then(
            res => {
                const worksheet = XLSX.utils.json_to_sheet(res.data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Отчет');
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
                saveAs(data, `${name}.xlsx`);
                setIsLoading(false);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    };

    return (
        <Button style={{
            height: "44px",
            background: "#BF0",
            color: "black",
            fontWeight: 600
        }}
            onClick={handleExport}
        >
            <p>Загрузить отчет</p>
            {
                isLoading && <Spinner color={"white"}/>
            }
        </Button>
    );
};

export default ExcelSave;
