import {Button, Card, Input, Spinner} from "@nextui-org/react";
import {useState} from "react";
import axios from "axios";

const Login = () => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFirst, setIsFirst] = useState(false);
    const [token, setToken] = useState("");
    const [sendData, setSendData] = useState({
        username: "",
        password: ""
    });
    const [changeData, setChangeData] = useState({
        password: "",
        password_again: ""
    });
    const submitData = () => {
        setIsLoading(true);
        axios.post("https://backend.sorpa.com/users/login/admin/", {...sendData}).then(
            res => {
                if (res.data.is_first){
                    setIsLoading(false);
                    setIsFirst(true);
                    setToken(`${res.data.token}`);
                    localStorage.setItem("role", `${res.data.role}`);
                }else{
                    localStorage.setItem("sorpaToken", `${res.data.token}`);
                    localStorage.setItem("role", `${res.data.role}`);
                    const timeoutId = setTimeout(() => {
                        window.location.reload();
                    }, 500);
                    return () => clearTimeout(timeoutId);
                }
            }
        ).catch(
            err => {
                setIsLoading(false);
                setError(err.response.data.message);
            }
        )
    }

    const changePassword = () => {
        setIsLoading(true);
        axios.post("https://backend.sorpa.com/users/change_password/admin/", {...changeData}, {headers: {
                "Authorization": `Token ${token}`
            }}).then(
            res => {
                localStorage.setItem("sorpaToken", `${token}`);
                const timeoutId = setTimeout(() => {
                    window.location.reload();
                }, 500);
                return () => clearTimeout(timeoutId);
            }
        ).catch(
            err => {
                setIsLoading(false);
                setError(err.response.data.message);
            }
        )
    }

    if (isLoading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
            }}>
                <Spinner/>
            </div>
        )
    }

    if (isFirst){
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                <Card style={{
                    background: "#121212",
                    width: 300,
                    maxHeight: 320,
                    borderRadius: 12,
                    padding: 16,
                    display: "flex",
                    flexDirection: "column",
                    gap: 16
                }}>
                    <h2 style={{padding: 0, margin: 0, color: "white", fontSize: 24, fontWeight: 800}}>Смените пароль</h2>
                    {
                        error && <p style={{color: "crimson", fontSize: 12}}>{error}</p>
                    }
                    <Input
                        type={"password"}
                        label="Пароль"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                        onChange={(event) => {
                            setChangeData({...changeData, password: event.target.value});
                            setError("");
                        }}
                        placeholder="Укажите новый пароль"
                    />
                    <Input
                        type={"password"}
                        label="Пароль"
                        size={"sm"}
                        radius="lg"
                        style={{color: "white"}}
                        className={"input-text-color"}
                        classNames={{
                            label: "input-text-color",
                            input: "text-white/90",
                            innerWrapper: "input-text-color",
                            inputWrapper: "input-main",
                        }}
                        onChange={(event) => {
                            setChangeData({...changeData, password_again: event.target.value});
                            setError("");
                        }}
                        placeholder="Повторите новый пароль"
                    />
                    <Button style={{
                        background: "#BF0",
                        color: "black",
                        fontWeight: 600
                    }}
                            onClick={changePassword}
                    >
                        <p>Сменить</p>
                    </Button>
                </Card>
            </div>
        )
    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
            <Card style={{
                background: "#121212",
                width: 300,
                maxHeight: 320,
                borderRadius: 12,
                padding: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16
            }}>
                <h2 style={{padding: 0, margin: 0, color: "white", fontSize: 24, fontWeight: 800}}>Вход</h2>
                {
                    error && <p style={{color: "crimson", fontSize: 12}}>{error}</p>
                }
                <Input
                    type={"email"}
                    label="Почта"
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    onChange={(event) => {
                        setSendData({...sendData, username: event.target.value});
                        setError("");
                    }}
                    placeholder="Укажите почту"
                />
                <Input
                    type={"password"}
                    label="Пароль"
                    size={"sm"}
                    radius="lg"
                    style={{color: "white"}}
                    className={"input-text-color"}
                    classNames={{
                        label: "input-text-color",
                        input: "text-white/90",
                        innerWrapper: "input-text-color",
                        inputWrapper: "input-main",
                    }}
                    onChange={(event) => {
                        setSendData({...sendData, password: event.target.value});
                        setError("");
                    }}
                    placeholder="Введите пароль"
                />
                <Button style={{
                    background: "#BF0",
                    color: "black",
                    fontWeight: 600
                }}
                        onClick={submitData}
                >
                    <p>Войти</p>
                </Button>
            </Card>
        </div>
    )
}

export default Login;
