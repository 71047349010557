import {
    Button,
    Card,
    Divider,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,Tabs, Tab, ModalContent, ModalHeader, ModalBody, Modal, Textarea
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {useNavigate} from "react-router";

const Reviews = () => {
    const history = useNavigate();
    const [personalReviews, setPersonalReviews] = useState([]);
    const [placeReviews, setPlaceReviews] = useState([]);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [sendData, setSendData] = useState({
        type: "",
        id: "",
        text: ""
    });
    useEffect(() => {
        axios.get("https://backend.sorpa.com/places/admin/reviews/list/").then(
            res => {
                setPersonalReviews(res.data.personal_comments);
                setPlaceReviews(res.data.branch_comments);
            }
        ).catch(
            err => {
                console.log(err.response);
            }
        )
    }, []);
    const submitCancel = (e) => {
        e.preventDefault();
        console.log(sendData);
        axios.post("https://backend.sorpa.com/places/admin/review/cancel/", {...sendData}).then(
            res => {
                history("/notifications");
            }
        ).catch(
            err => {

            }
        )
    }
    return (
        <>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16,
                marginTop:20
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16,
                            marginBottom:40
                        }}>
                            <div style={{color: "white", fontWeight: "bold"}}>
                                <div className="flex w-full flex-col">
                                    <h1 className={"text-[24px] mb-[24px]"}>Отзывы</h1>
                                    <Tabs aria-label="Options"
                                          variant={"solid"}
                                          color={"white"}
                                          classNames={{
                                              tabList: "tablist-back",
                                              cursor: "tablist-tab",
                                              tabContent: "tablist-text group-data-[selected=true]:text-tablist-text"
                                          }}
                                    >
                                        <Tab key="place" title="Заведение">
                                            <Card
                                                shadow={"none"}
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    background:"none",
                                                    gap: 16,
                                                }}>
                                                <p style={{color: "white", fontWeight: "bold"}} className={"py-[12px]"}>Количество отзывов: {placeReviews.length}</p>
                                                <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                                                <div>
                                                    {
                                                        placeReviews.length === 0 ?
                                                            <div style={{
                                                                width: "100%",
                                                                height: "calc(100vh - 250px)",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}>
                                                                <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                                    вашему запросу ничего не найдено</p>
                                                            </div> :
                                                            <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                                                   classNames={{
                                                                       base: 'table-wrapper',
                                                                       table: "table",
                                                                       wrapper: "base-wrapper"
                                                                   }}
                                                            >
                                                                <TableHeader>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Пользователь</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Текст</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Оценка</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Дата</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Отменен?</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Действия</TableColumn>
                                                                </TableHeader>
                                                                <TableBody>
                                                                    {
                                                                        placeReviews.map((review, index) => {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{review.user}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{review.text}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{review.score}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{review.created_at}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{review.is_canceled ? "Да" : "Нет"}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{!review.is_canceled ? <Button
                                                                                        onClick={() => {
                                                                                            setCancelModalOpen(true);
                                                                                            setSendData({
                                                                                                id: review.id,
                                                                                                type: "branch"
                                                                                            })
                                                                                        }}
                                                                                        size={"sm"}>
                                                                                        Отменить
                                                                                    </Button> : "-"}</p></TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                    }
                                                </div>
                                            </Card>
                                        </Tab>
                                        <Tab key="personal" title="Персонал">
                                            <Card
                                                shadow={"none"}
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    background:"none",
                                                    gap: 16,
                                                }}>
                                                <p style={{color: "white", fontWeight: "bold"}} className={"py-[12px]"}>Количество отзывов: {personalReviews.length}</p>
                                                <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                                                <div>
                                                    {
                                                        personalReviews.length === 0 ?
                                                            <div style={{
                                                                width: "100%",
                                                                height: "calc(100vh - 250px)",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}>
                                                                <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                                    вашему запросу ничего не найдено</p>
                                                            </div> :
                                                            <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                                                   classNames={{
                                                                       base: 'table-wrapper',
                                                                       table: "table",
                                                                       wrapper: "base-wrapper"
                                                                   }}
                                                            >
                                                                <TableHeader>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Пользователь</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Текст</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Оценка</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Дата</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Отменен?</TableColumn>
                                                                    <TableColumn style={{
                                                                        background: "#525255",
                                                                        color: "white",
                                                                        fontWeight: "bold"
                                                                    }}>Действия</TableColumn>
                                                                </TableHeader>
                                                                <TableBody>
                                                                    {
                                                                        personalReviews.map((review, index) => {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{review.user}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{review.text}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                                        margin: 0
                                                                                    }}>{review.score}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{review.created_at}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{review.is_canceled ? "Да" : "Нет"}</p></TableCell>
                                                                                    <TableCell><p style={{
                                                                                        margin: 0
                                                                                    }}>{!review.is_canceled ? <Button
                                                                                        onClick={() => {
                                                                                            setCancelModalOpen(true);
                                                                                            setSendData({
                                                                                                id: review.id,
                                                                                                type: "personal"
                                                                                            })
                                                                                        }}
                                                                                        size={"sm"}>
                                                                                        Отменить
                                                                                    </Button> : "-"}</p></TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                    }
                                                </div>
                                            </Card>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <Modal isOpen={cancelModalOpen} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Отменить комментарий</h2>
                            <Button onClick={() => {
                                setCancelModalOpen(false);
                                setSendData(
                                    {
                                        id: null,
                                        text: "",
                                        type: ""
                                    }
                                )
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            <form
                                onSubmit={submitCancel}
                                className={"w-full"}
                            >
                                <Textarea
                                    type={"text"}
                                    label="Напишите причину отмены"
                                    value={sendData.text}
                                    size={"sm"}
                                    radius="lg"
                                    isRequired={true}
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setSendData({...sendData, text: event.target.value})
                                    }}
                                />
                                <Button
                                    fullWidth={true}
                                    type={"submit"}
                                    style={{
                                        marginTop:20,
                                    background: "#BF0",
                                    color: "black",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginBottom:20
                                    }}
                                >
                                    <p>Отправить</p>
                                </Button>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default Reviews;
